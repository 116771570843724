@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter.ttf') format('truetype');
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
}

h1 {
    @apply text-3xl my-4 font-bold;
}
h2 {
    @apply text-2xl my-3 font-bold;
}
h3 {
    @apply text-xl my-3 font-bold;
}
p {
    @apply my-4;
}
blockquote {
    @apply p-4 my-4 border-s-4 border-gray-300 bg-gray-100 dark:border-gray-500 dark:bg-gray-800;
}
ol {
    @apply list-decimal list-inside;
}
ul {
    @apply list-disc list-inside;
}
pre {
    @apply p-4 my-4 bg-gray-900 dark:bg-gray-800;
}
pre code {
    @apply text-gray-100;
}
